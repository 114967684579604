<template>
  <div ref="wrapper">
    <div v-if="field.label && !isModal" class="row align-items-center">
      <div class="col-8">
        <label>
          {{ field.label }}
          <span v-if="field.required" class="red--text">*</span>
          <i
            v-if="field.helpSnippet"
            v-b-popover.hover.top="$t(field.helpSnippet)"
            class="fal fa-question-circle ml-1"
          />
        </label>
      </div>
      <div class="col-4">
        <b-form-checkbox
          v-if="
            field.optionalDirectInput !== undefined && field.optionalDirectInput
          "
          v-model="isOutputField"
          switch
        >
          {{ $t("general.directInput") }}
        </b-form-checkbox>
      </div>
    </div>
    <div class="d-flex flex-nowrap">
      <div style="flex-grow: 1; max-width: 100%">
        <div>
          <div class="input-group">
            <div v-if="field.prepend" class="input-group-prepend">
              <span class="input-group-text">{{ field.prepend }}</span>
            </div>
            <v-autocomplete
              v-if="!isOutputField"
              v-model="field.value"
              class="form-control mt-0"
              :class="validationState"
              :items="selectOptions"
              item-text="label"
              item-value="value"
              :no-data-text="$t('workflowDesigner.noSelectData')"
              :menu-props="{ offsetY: true }"
              :disabled="isDisabled"
              :multiple="false"
              @input="selectChange"
            />
            <input
              v-else
              v-model="field.value"
              type="text"
              class="form-control"
            />
            <div v-if="field.append" class="input-group-append">
              <span class="input-group-text">{{ field.append }}</span>
            </div>
          </div>
        </div>
        <span v-if="field.hint" class="form-text text-muted">
          {{ field.hint }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "@/main";

export default {
  props: {
    field: {
      type: Object
    },
    areaInvalid: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      edited: false,
      value: "",
      configValue: null,
      valueIndex: null,
      cancelAction: false,
      isOutputField: false
    };
  },
  computed: {
    isDisabled() {
      return (
        this.disabled ||
        (this.field.readonly !== undefined && this.field.readonly)
      );
    },
    validationState: function () {
      if (!this.field.required || (!this.edited && !this.areaInvalid)) {
        return "";
      }
      if (!this.value) {
        return "is-invalid";
      }
      return "is-valid";
    },
    selectOptions: function () {
      if (!this.field.options) {
        return [];
      }

      let options = this.field.options.map(o => {
        if (typeof o === "string") {
          o = { value: o, label: o, group: null };
        }
        let label = o.value;
        if (o.label) {
          label = o.label;
        } else if (this.$te(`${this.field.option}Options.${o.value}`)) {
          label = this.$t(`${this.field.option}Options.${o.value}`);
        }

        return {
          value: o.value,
          label: label,
          group: o.group ?? null
        };
      });

      let optionsSorted = options;
      if (this.field.sort === "desc" || this.field.sort === -1) {
        optionsSorted = options.sort((a, b) => {
          if (a.group && b.group) {
            return b.group.localeCompare(a.group);
          }
          return b.label.localeCompare(a.label);
        });
      } else if (this.field.sort === "asc" || this.field.sort === 1) {
        optionsSorted = options.sort((a, b) => {
          if (a.group && b.group) {
            return a.group.localeCompare(b.group);
          }
          return a.label.localeCompare(b.label);
        });
      }

      let newOptions = [];
      let lastGroup = null;
      optionsSorted.forEach(option => {
        if (option.group !== null && lastGroup !== option.group) {
          newOptions.push({
            header: option.group
          });
          lastGroup = option.group;
        }
        newOptions.push(option);
      });
      return newOptions;
    }
  },
  watch: {
    value: function () {
      this.edited = true;
      let newValue = this.value;

      if (typeof newValue !== "boolean" && Number(newValue)) {
        newValue = parseFloat(newValue);
      } else if (newValue === "true" || newValue === "false") {
        newValue = Boolean(newValue);
      }

      this.$set(this.field, "value", newValue);
      this.$emit("change", newValue);
    }
  },
  mounted() {
    this.value = this.field.value ?? "";
    this.checkDirectInput();
  },
  methods: {
    checkDirectInput() {
      if (
        this.field.type === "select" &&
        !this.selectOptions.find(o => o.value === this.field.value) &&
        this.field.value
      ) {
        this.isOutputField = true;
      }
    },
    selectChange(value) {
      this.value = value;
      if (this.field.onChange !== undefined) {
        bus.$emit(
          "fireAction",
          {
            name: this.field.onChange,
            label: this.field.onChange
          },
          false
        );
      }
      setTimeout(this.checkRequiredFields, 100);
    },
    checkRequiredFields() {
      bus.$emit("checkRequiredFields");
    }
  }
};
</script>

<style lang="scss">
.input-group-append {
  .input-group-text {
    border: 1px solid #e4e6ef;
  }
}
.v-input--selection-controls__input .v-icon {
  color: #e4ebef;
}
</style>
